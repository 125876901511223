<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" style="text-align: justify">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Sprawdzenie kontrahenta*</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-bottom: 45px;">
      <div
        v-for="(key, val) in contractors"
        :key="key"
        class="row"
      >
        <div class="col-sm-12">
          <div class="services-boxes wow fadeInUp animated" style="visibility: hidden;">
            <div class="services-boxes-content">
              <h3 style="  text-transform: inherit;">
                <a :href="key" target="_blank">{{ val }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 40px;">
      <div class="col-sm-12">
        <div class="wow fadeInDown animated" style="visibility: hidden;">
          <div class="services-boxes-content">
            <p style="text-align: center; margin-bottom: 0;">
              * Przekierowanie na strony urzędowe. Nie ponosimy odpowiedzialności za prawidłowość i aktualność podanych na nich informacji.<br>
              ** Wykazy podmiotów niezarejestrowanych, wykreślonych lub przywróconych do rejestru jako podatnicy VAT.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: 'Sprawdzenie kontrahenta - Biuro rachunkowe Poznań "STORNO"'
  },
  data () {
    return {
      contractors: {
        'Weryfikacja statusu VAT, VAT-UE i NIP': 'https://www.podatki.gov.pl/wyszukiwarki/',
        'Wyszukiwanie w rejestrze Regon (GUS)': 'https://wyszukiwarkaregon.stat.gov.pl/appBIR/index.aspx',
        'Wyszukiwanie w CEIDG': 'https://prod.ceidg.gov.pl/CEIDG/ceidg.public.ui/Search.aspx',
        'Wyszukiwanie w KRS': 'https://ems.ms.gov.pl/krs/wyszukiwaniepodmiotu',
        'Informacja o udzielonej pomocy publicznej': 'https://sudop.uokik.gov.pl/home',
        'Wykaz podmiotów VAT**': 'http://www.mf.gov.pl/krajowa-administracja-skarbowa/dzialalnosc/wykaz-podmiotow-niezarejestrowanych-oraz-wykreslonych-i-przywroconych-do-rejestru-vat'
      }
    }
  }
}
</script>
